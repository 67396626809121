$social-sub-color: #626262;
$nav-link-color: $body-color;

header {
  min-height: 82px;

  .ti-hivve-logo {
    width: 210px;
  }

  .vv-hivve-logo {
    width: 150px;

    @include media-breakpoint-between(xs, lg) {
      width: 125px;
    }
  }

  nav.navbar {
    .navbar {
      &-nav .nav {
        &-link {
          @include font-type(type-26);
        }
      }

      @include media-breakpoint-up(md) {
        &-nav .nav {
          &-link {
            @include font-type(nav-link);
            color: $nav-link-color;
            padding: $spacer * 1.8 0;
          }

          &-item {
            padding: $spacer * 1.4 0;
            margin: 0 $spacer * 1.5;

            &.active {
              border-bottom: 3px solid theme-color(primary);

              .nav-link {
                color: theme-color(primary);
              }
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        &-collapse {
          position: absolute;
          z-index: 1;
        }
      }

      &-toggler {
        .icon {
          transition: height 200ms;
          transition-timing-function: cubic-bezier(0, 0, 0.6, 1);
        }
      }
    }
  }

  .auth-buttons .btn {
    min-width: 142px;
  }

  .profile-menu-button {
    @include media-breakpoint-up(md) {
      .icon.icon-chevron-right {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      border: none;

      .icon.icon-caret-down {
        display: none;
      }
    }
  }
}

.nav-profile {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  .name {
    @include font-type(type-22);
    color: $charcoal;
  }
}

.dropdown-menu {
  .user-avatars_image {
    @include user-image-style(60px, 60px, 50%);
    @include user-avatar-default-style(60px, 60px, 50%);
  }

  .user-avatars_default-avatar {
    @include user-image-style(60px, 60px, 50%);
    @include user-avatar-default-style(60px, 60px, 50%);
    margin-bottom: 0;
    padding-top: 0;

    &[title] {
      padding-top: $spacer * 0.2;
    }
  }

  &.my-account {
    margin-top: $spacer * 1.2;
  }

  .my-account {
    &_options {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: $spacer * 2;
      margin-bottom: $spacer * 2;
      border-bottom: 1px solid $bright-gray;

      .name {
        @include font-type(type-25);
        color: $charcoal;
        margin-bottom: $spacer * 0.6;
      }

      .job-title {
        @include font-type(type-9);
        margin-bottom: 0;
        color: $gray-dark;
      }
    }

    &_item {
      padding: $spacer * 0.9 0;

      &.active {
        background-color: $ghost;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .dropdown-item {
        @include font-type(type-22);
        line-height: 1.85;
        padding: 0 $spacer * 1.5 0 0;
        display: inline-flex;
        align-items: center;
        color: $charcoal;
        background-repeat: no-repeat;
        background-position: center right;
        background-image: url('/assets/icons/svg/chevron-right.svg');

        &:hover {
          background-color: transparent;
        }

        &:active {
          color: theme-color(primary);
        }

        &_social-sub-menu {
          @include font-type(social-sub-menu);
          color: $social-sub-color;
        }

        .label {
          width: 37px;
          height: 37px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: $spacer * 1.2;
          background-color: $gray-light-8;
        }
      }
    }
  }
}
