.form,
.input {
  &-group {
    label {
      line-height: $line-height-md;
      margin-bottom: $spacer * 1.3;

      &.required:after {
        content: ' *';
        color: $red;
      }
    }

    input.form-control {
      @include input-style;

      &:not(:disabled) {
        background-color: $white;
      }

      &:focus {
        border-color: $spun-pearl;
      }

      &::placeholder {
        color: $body-color;
        opacity: 0.5;
      }

      & + i {
        cursor: pointer;
        position: absolute;
        right: 13px;
        bottom: 17px;
      }

      &.ng-dirty.ng-invalid {
        border-color: $red;
      }
    }

    .password-wrapper {
      position: relative;
    }
  }
}

.form {
  &-select {
    @include input-style;

    &:focus {
      border-color: $spun-pearl;
    }
  }

  &-check-input {
    &[type='checkbox'] {
      border-radius: 1px;
    }

    &[type='radio'],
    &[type='checkbox'] {
      border-color: $ghost;
      background-color: $white;
      background-size: auto;
    }

    &:checked {
      &[type='radio'],
      &[type='checkbox'] {
        border-color: $body-color;
      }

      &[type='radio'] {
        background-image: url('/assets/icons/svg/oval.svg');
      }

      &[type='checkbox'] {
        background-image: url('/assets/icons/svg/check-cyan.svg');
      }
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
