.legal {
  padding: $spacer * 6 $spacer * 5;

  &-container {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 8;
    text-align: justify;

    .card {
      &-header {
        @include font-type(project-sections-headers);
        color: $primary;
        padding-bottom: $spacer * 1.6;
      }
      &-content {
        font-size: 14px;
      }
    }

    ul li::marker {
      color: theme-color(secondary);
    }

    .eula {
      ol {
        counter-reset: item;
      }

      li {
        display: block;
      }

      li:before {
        content: counters(item, '.') ': ';
        counter-increment: item;
      }
    }
  }
}
