.top-bar {
  background-color: $white;
  padding: $spacer * 1.5 $spacer * 3;
  margin-top: $spacer * 2.5;
  margin-bottom: $spacer * 3;
  border-radius: $border-radius-md;

  &_title {
    @include media-breakpoint-down(sm) {
      margin-right: $spacer * 5.5;
    }
  }

  .sort-control,
  .page-size-control {
    margin-left: $spacer * 1.5;
    margin-right: $spacer * 4;

    @include media-breakpoint-down(sm) {
      margin-right: $spacer;
    }
  }

  .sort-control {
    width: 94px;
    white-space: normal;
  }

  .page-size-control {
    width: 51px;
  }

  .layout-btn {
    cursor: pointer;

    &:not(.active) {
      opacity: 0.3;
    }
  }
}
