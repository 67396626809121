.liked-count {
  width: 90px;
  height: 30px;
  padding: $spacer * 0.9 $spacer * 5.3 $spacer * 0.7;
  background: $white;
  @include font-type(type-35);
  color: $charcoal;
  border-color: $gray-light-4;

  .heart-loader {
    margin-top: $spacer * 0.32;
    animation: rotate 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }

  .heart-loader span {
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    background: $pink-color;
    border: 1px solid $pink-color;
    border-radius: 100%;
  }

  .heart-loader span:nth-child(1) {
    transform: translate(-5px, -5px);
    animation: heart-shape_1 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }

  .heart-loader span:nth-child(2) {
    transform: translate(5px, -5px);
    animation: heart-shape_2 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }

  .heart-loader span:nth-child(3) {
    position: relative;
    border-radius: 0px;
    transform: scale(0.98) rotate(-45deg);
    animation: heart-shape_3 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }

  @keyframes rotate {
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }

  @keyframes heart-shape_1 {
    60% {
      transform: scale(0.4);
    }
  }

  @keyframes heart-shape_2 {
    40% {
      transform: scale(0.4);
    }
  }

  @keyframes heart-shape_3 {
    50% {
      border-radius: 100%;
      transform: scale(0.5) rotate(-45deg);
    }
    100% {
      transform: scale(0.98) rotate(-45deg);
    }
  }
}
