.main-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .divider {
    width: 2px;
    border-right: 2px solid #e4e4e4;
    height: 20px;
    color: $gray-prod-divider;
    opacity: 1;
  }

  .navbar-nav {
    .nav-item {
      .dropdown {
        .nav-link {
          outline: none;
        }

        .dropdown-menu {
          &.apps-dropdown {
            padding: 0;
            position: absolute;
            min-width: 187px !important;
          }

          .dropdown-item {
            padding-top: $spacer * 1.2;
            padding-left: $spacer * 1.93;
            padding-bottom: $spacer * 1.2;

            .dropdown-item:active {
              background-color: $gray-light-17;
            }

            &:not(:last-child) {
              border-bottom: 1px solid $gray-prod-divider;
            }

            &:hover {
              background-color: $gray-light-16;
            }

            &:first-child:hover {
              border-radius: 8px 8px 0 0;
            }

            &:last-child:hover {
              border-radius: 0 0 8px 8px;
            }

            .btn-signout {
              box-shadow: none;
              color: $gray-dark-10;
              font-size: 14px;
              text-decoration: none;
            }

            .app-logo {
              height: 36px;
            }

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
