$btn-padding-xs: $spacer * 0.7;
$btn-padding-sm: $spacer * 1.2;
$btn-padding-md: $spacer * 1.7;

$btn-line-height: $line-height-sm;
$btn-link-line-height: $line-height-lg;
$btn-border-radius: 7px;
$btn-border-radius-alt: $border-radius-sm;

$btn-width-ls: 216px;
$btn-width-md: 188px;
$btn-width-sm: 117px;
$btn-width-xs: 89px;

$btn-bg-color--highlight: $cyan-dark;
$btn-bg-color--disabled: $gray;

$btn-secondary-bg-color: $gray-light;

$btn-outline-bg-color: $white;
$btn-outline-bg-color--disabled: $white;

$btn-link-color--highlight: $cyan-dark;

$btn-share-bg-color: $blue;

.btn {
  color: $white;
  border-radius: $btn-border-radius;
  padding: $btn-padding-md;
  line-height: $btn-line-height;
  text-transform: uppercase;

  &-primary {
    &:hover,
    &:active,
    &:focus {
      background-color: $btn-bg-color--highlight;
      border-color: $btn-bg-color--highlight;
      color: $white;
    }

    // Disabled state
    &.disabled,
    &[disabled] {
      background-color: $btn-bg-color--disabled;
      border-color: $btn-bg-color--disabled;
      opacity: 1;
    }
  }

  &-secondary {
    border: none;
    color: $black;
    min-width: $btn-width-ls;
    padding: $btn-padding-xs;
    border-radius: $btn-border-radius-alt;
    background-color: $btn-secondary-bg-color;

    & > span {
      display: inline-block;
      border-bottom: 1px solid;
      line-height: $btn-link-line-height;
    }

    &:hover,
    &:active,
    &:focus {
      color: $black;
      background-color: $btn-secondary-bg-color;
    }
  }

  &-outline-primary {
    background-color: $btn-outline-bg-color;
    border-color: theme-color(primary);
    color: theme-color(primary);

    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: theme-color(primary);
      border-color: theme-color(primary);
    }

    // Disabled state
    &.disabled,
    &[disabled] {
      background-color: $btn-outline-bg-color--disabled;
      border-color: $gray-dark;
      color: $gray-dark;
      opacity: 1;
    }
  }

  &-link {
    padding: 0;
    color: theme-color(primary);
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: $btn-link-color--highlight;
    }
  }

  &-download {
    color: $charcoal;
    min-width: $btn-width-sm;
    padding: $btn-padding-sm;
    border-color: $gray-light;
    background-color: $btn-outline-bg-color;

    &:hover,
    &:active,
    &:focus {
      color: $black;
      border-color: $gray-light;
      background-color: $btn-outline-bg-color;
    }
  }

  &-print {
    color: $black;
    padding: $btn-padding-sm;
    border-color: $gray-light;
    background-color: $btn-outline-bg-color;
  }

  &-share {
    color: $white;
    padding: $btn-padding-sm;
    min-width: $btn-width-xs;
    background-color: $btn-share-bg-color;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: $btn-share-bg-color;
    }
  }

  &-upload {
    color: theme-color(primary);
    min-width: $btn-width-md;
    padding: $btn-padding-sm;
    border-color: theme-color(primary);
    border-radius: $btn-border-radius-alt;
    background-color: $btn-outline-bg-color;

    &:hover,
    &:active,
    &:focus {
      color: theme-color(primary);
      border-color: theme-color(primary);
      background-color: $btn-outline-bg-color;
    }
  }

  &-follow {
    color: $white;
    padding: $btn-padding-sm;
    border-color: $white;
    border-radius: $btn-border-radius-alt;

    &:hover,
    &:active,
    &:focus {
      color: $white-light;
      border-color: $white-light;
    }
  }

  &-follow-project {
    color: $body-color;
    min-width: $btn-width-sm;
    padding: $btn-padding-sm;
    border-color: $gray-light-11;
    background-color: $btn-outline-bg-color;

    &:hover,
    &:active,
    &:focus {
      border-color: $green-light;
      color: $white;
      background-color: $green-light;
    }
  }

  &-outline-secondary {
    width: 38px;
    height: 38px;
    padding: 0;
    margin: 0 $spacer * 0.5;
    line-height: 0;
    border-radius: $border-radius-md;
    border: 1px solid $platinum;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $platinum;
      background-color: transparent;
    }
  }

  &-plus {
    @include qty-button('/assets/icons/svg/plus.svg');
  }

  &-minus {
    @include qty-button('/assets/icons/svg/minus.svg');
  }

  &__md {
    min-width: 131px;
  }
}
