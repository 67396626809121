.user-avatars {
  @include user-image-group;

  &_wrapper {
    .random-color-1 {
      color: $orange-color;
      background: $orange-background;
    }
    .random-color-2 {
      color: $pink-color;
      background: $pink-background;
    }
    .random-color-3 {
      color: $light-green-color;
      background: $light-green-background;
    }
    .random-color-4 {
      color: $blue-color;
      background: $blue-background;
    }
    .random-color-5 {
      color: $purple-color;
      background: $purple-background;
    }
    .random-color-6 {
      color: $dark-green-color;
      background: $dark-green-background;
    }
  }
}
