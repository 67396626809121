// Custom Colors
$white: #ffffff;
$black: #000000;
$gray: #9ca7a9;
$gray-dark: #797979;
$gray-dark-2: #ababab;
$gray-dark-3: #7e7e7e;
$gray-dark-5: #b5b5b8;
$gray-dark-6: #898c8a;
$gray-dark-7: #999999;
$gray-dark-8: #535357;
$gray-dark-9: #92929c;
$gray-dark-10: #657177;
$gray-light: rgba(180, 187, 204, 0.4);
$gray-light-2: #dadadd;
$gray-light-3: #b5b4ba;
$gray-light-4: #f3efef;
$gray-light-5: #e5e5e5;
$gray-light-6: #d3d3d3;
$gray-light-7: #d7d7d7;
$gray-light-8: #e9ebf3;
$gray-light-9: #909090;
$gray-light-10: #b1b2b2;
$gray-light-11: #d9d3d3;
$gray-light-12: #babacc;
$gray-light-13: #979797;
$gray-light-14: #b1b1b8;
$gray-light-15: #d8d8d8;
$gray-light-16: #ebebeb;
$gray-light-17: #e9ecef;
$platinum: #e4e4e4;
$blue: #3474df;
$blue-light: #1398ce;
$cyan-dark: #028494;
$green: #ccf3cc;
$green-dark: #329c1e;
$green-dark-2: #047c3c;
$green-light: #8ec73f;
$yellow: #eeef99;
$blush: #f1c6c6;
$red: #ff0000;
$red-2: #d00f0f;
$cinnabar: #f05e6b;
$spun-pearl: #a1a1a4;
$ghost: #c1c1c5;
$gray-prod-divider: #f3f3f3;
$crayola: #b3b3bf;
$crayola-crystal: #d5d5d5;
$sonic-silver: #777779;
$bright-gray: #ececec;
$bondi-blue: #049fb2;
$charleston-green: #272a2e;
$eerie-black: #1a1917;
$white-light: rgba(
  $color: $white,
  $alpha: 0.8
);
$dim-gray: #666666;
$charcoal: #505052;
$blue-charcoal: #212529;

// Evidence type colors
$evidence-orange: #f8901e;
$evidence-lime-green: #8ec73f;
$evidence-blue: #3280c9;
$evidence-red: #f05e6b;
$evidence-dark-green: #19a0b4;
$evidence-purple: #ab69c6;
$evidence-dark-grey: #74737a;
$evidence-yellow: #ffd872;
$evidence-light-green: #8dd7bf;
$evidence-light-purple: #6c88c4;
$evidence-dark-red: #c05780;
$evidence-pink: #ff60a8;
$evidence-dark-blue: #0065a2;

// Avatar-colors
$orange-color: #ed953d;
$orange-background: #ffd08f;

$pink-color: #e35a67;
$pink-background: #f4a5af;

$light-green-color: #8dc63f;
$light-green-background: #d1e6b2;

$blue-color: #4480c2;
$blue-background: #9cbee4;

$purple-color: #9f6daf;
$purple-background: #ceb8d9;

$dark-green-color: #059fb3;
$dark-green-background: #9cc8d4;

$spinner-orange-color: #f78f1e;

// Typography
/**
 * Font imports
 */
$font-import: (
  CircularStd: (
    Book: (
      weight: 400,
      style: normal
    ),
    Medium: (
      weight: 500,
      style: normal
    ),
    Bold: (
      weight: 700,
      style: normal
    )
  )
);

/**
 * CSS Font family names with websafe fallback
 * usage:
 * font-family: font-family-type(primary)
 * font: 12px/24px font-family-type(primary)
 */
$font-family-type: (
  primary: (
    'Circular Std',
    CircularStd,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif
  )
);

/**
 * Font Types
 */
$font-types: (
  type-1: (
    font: 400 15px font-family-type(primary),
    letter-spacing: normal,
    line-height: 1.4
  ),
  type-2: (
    font: 500 20px font-family-type(primary),
    letter-spacing: normal,
    line-height: 1.73
  ),
  type-3: (
    font: 700 13px font-family-type(primary),
    letter-spacing: 0.5,
    line-height: 1.38
  ),
  type-4: (
    font: 700 15px font-family-type(primary),
    letter-spacing: normal,
    line-height: 1.4
  ),
  type-5: (
    font: 400 14px font-family-type(primary),
    letter-spacing: normal,
    line-height: 1.14
  ),
  type-6: (
    font: 700 9px font-family-type(primary),
    line-height: 1.22
  ),
  type-7: (
    font: 400 11px font-family-type(primary),
    line-height: 1.27
  ),
  type-8: (
    font: 400 12px font-family-type(primary),
    line-height: 1.17
  ),
  type-9: (
    font: 400 13px font-family-type(primary),
    line-height: 1.15
  ),
  type-10: (
    font: 400 16px font-family-type(primary),
    line-height: 1
  ),
  type-11: (
    font: 400 15px font-family-type(primary),
    line-height: 1.2
  ),
  type-12: (
    font: 400 16px font-family-type(primary)
  ),
  type-13: (
    font: 400 8px font-family-type(primary)
  ),
  type-15: (
    font: 400 18px font-family-type(primary)
  ),
  type-16: (
    font: 700 13px font-family-type(primary),
    line-height: 1.15
  ),
  type-17: (
    font: 700 14px font-family-type(primary),
    line-height: 1.14
  ),
  type-18: (
    font: 400 15px font-family-type(primary),
    line-height: 1.8
  ),
  type-19: (
    font: 700 16px font-family-type(primary)
  ),
  type-20: (
    font: 700 11px font-family-type(primary),
    line-height: 1.27
  ),
  type-21: (
    font: 700 6px font-family-type(primary),
    line-height: 1.83
  ),
  type-22: (
    font: 500 13px font-family-type(primary),
    line-height: 1.15
  ),
  type-23: (
    font: 400 13px font-family-type(primary),
    line-height: 1.85
  ),
  type-24: (
    font: 500 14px font-family-type(primary),
    line-height: 1.14
  ),
  type-25: (
    font: 500 15px font-family-type(primary),
    line-height: 1.2
  ),
  type-26: (
    font: 500 12px font-family-type(primary),
    line-height: 1.17
  ),
  type-27: (
    font: 500 11px font-family-type(primary),
    line-height: 1.18
  ),
  type-28: (
    font: 500 18px font-family-type(primary),
    line-height: 1.17
  ),
  type-29: (
    font: 500 16px font-family-type(primary),
    line-height: 1.19
  ),
  type-30: (
    font: 300 12px font-family-type(primary),
    line-height: 1.17,
    opacity: 0.9
  ),
  type-31: (
    font: 500 11px font-family-type(primary),
    line-height: 2
  ),
  type-32: (
    font: 600 75% font-family-type(primary),
    line-height: 1
  ),
  type-33: (
    font: 500 17.5px font-family-type(primary)
  ),
  type-34: (
    font: 500 10px font-family-type(primary),
    line-height: 1.2
  ),
  type-35: (
    font: 700 16px font-family-type(primary),
    letter-spacing: normal,
    line-height: 0.9
  ),
  type-36: (
    font: 700 50px font-family-type(primary),
    letter-spacing: normal,
    line-height: 55px
  ),
  type-37: (
    font: 450 18px font-family-type(primary),
    letter-spacing: normal,
    line-height: 20px
  ),
  type-38: (
    font: 700 40px font-family-type(primary),
    letter-spacing: normal,
    line-height: 45px
  ),
  type-39: (
    font: 700 30px font-family-type(primary),
    letter-spacing: normal,
    line-height: 30px
  ),
  default: (
    type: type-1
  ),
  headings: (
    type: type-2
  ),
  headings-bold: (
    type: type-3
  ),
  slogan: (
    type: type-4
  ),
  evidence-section-title: (
    type: type-28
  ),
  evidence-name: (
    type: type-28
  ),
  evidence-date: (
    type: type-30
  ),
  preference-subtitle: (
    type: type-23
  ),
  nav-link: (
    type: type-24
  ),
  internal-link: (
    type: type-31
  ),
  collaboration-badge: (
    type: type-34
  ),
  headline-description: (
    font: 400 14px font-family-type(primary)
  ),
  project-sections-headers: (
    font: 500 15px font-family-type(primary),
    line-height: 1.2
  ),
  project-short-description: (
    type: type-18,
    line-height: 1.5
  ),
  welcome-copy: (
    type: type-12,
    line-height: inherit
  ),
  default-avatar: (
    type: type-4,
    line-height: 1.2
  ),
  social-sub-menu: (
    font: 400 10px font-family-type(primary),
    line-height: 1.5
  ),
  cta-header: (
    type: type-29,
    line-height: 1.25
  ),
  cta-label: (
    type: type-10,
    line-height: 1.25
  ),
  team-heading: (
    type: type-31,
    line-height: 1.25
  ),
  sdg-counter-header: (
    font: 500 17px font-family-type(primary),
    line-height: 1.2
  ),
  sdg-counter-time: (
    font: 700 26px font-family-type(primary),
    line-height: 2
  )
);

// Bootstrap overrides
// Colors
$primary: #19a0b4 !default;
$secondary: #f68f1e !default;

$body-bg: #f7f5fa !default;
$body-color: #505052 !default;

// Typography
$font-size-base: 0.8125rem !default;

$h1-font-size: 24px !default;
$h2-font-size: 24px !default;
$h3-font-size: 20px !default;
$h4-font-size: 16px !default;
$h5-font-size: 15px !default;
$h6-font-size: 14px !default;

$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 600 !default;

$headings-font-family: font-family-type(primary);
$headings-font-weight: $font-weight-normal;

$text-muted: $gray-dark-2 !default;

//Spacing
$spacer: 0.625rem !default; // 10px

// Forms
$input-height: 47px !default;
$form-label-font-size: 13px !default;

// Cards
$card-spacer-y: $spacer * 3 !default;
$card-border-radius: 6px !default;

$font-family-sans-serif: font-family-type(primary);
$font-weight-base: $font-weight-light;

// Custom
$line-height-sm: 14px;
$line-height-md: 15px;
$line-height-lg: 20px;
$line-height-xl: 28px;

$border-radius-xs: 3px;
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius-ls: 8px;
$border-radius-lg: 10px;
$border-radius-xl: 11px;
$border-radius-xxl: 13px;

$card-padding-default: $spacer * 3;
$card-padding-top-first: $spacer * 1.8;
$card-padding-bottom-first: $spacer * 2;
$card-padding-top-footer: $spacer * 1.9;
$card-padding-bottom-footer: $spacer * 2.4;

// SDGs
$sdg-images: (1, #e5233d), (2, #dda73a), (3, #4ca046), (4, #c7202f), (5, #ef402b), (6, #28bfe6),
  (7, #fcc410), (8, #a31d44), (9, #f26a2f), (10, #e01683), (11, #f89d2a), (12, #4ca046),
  (13, #407f46), (14, #1e97d4), (15, #58ba47), (16, #146a9f), (17, #16486b);

$zindex-spinner: 100000;
