.account-container {
  .verbiage {
    @include font-type(type-8);
  }

  .title {
    margin-bottom: $spacer * 3.5;
  }

  .subtitle {
    margin-bottom: $spacer * 2.5;
  }

  .contact-tooltip {
    @include font-type(type-7);
    opacity: 0.6;
    margin-bottom: $spacer * 2.2;
  }

  .account {
    &-details__overview {
      margin-top: $spacer * 5.3;
    }
  }

  .social-networks__wrapper,
  .email-notifications__wrapper {
    .subtitle {
      @include font-type(preference-subtitle);
    }

    .form-group {
      margin-top: $spacer * 2;

      &__title {
        margin-bottom: $spacer * 2;
      }
    }
  }

  app-search-result-top-bar > div {
    border: 1px solid $gray-light-7;
  }

  #project-content .project-card {
    border: 1px solid $gray-light-7;
  }
}

.outlet-body .card-body {
  padding-top: $spacer * 4.2;

  .form-group {
    .upload-avatar-label {
      color: $gray-light-14;
    }

    .avatar-upload_wrapper {
      .user-avatars {
        &_default-avatar,
        &_image {
          width: 70px;
          height: 70px;
        }
      }

      &.disabled {
        .e-btn {
          border-color: $gray-dark;
          color: $gray-dark;
          pointer-events: none;
        }
      }

      .e-btn {
        @extend .btn, .btn-outline-primary;
        @include font-type(type-8);
        width: 170px;
        height: 42px;
        padding: $spacer;

        @media (min-width: 375px) {
          width: 188px;
        }
      }

      .scan-error {
        margin-left: $spacer * 9.5;
        position: relative;
        bottom: $spacer * 2;
      }
    }

    .contact-method {
      &.disabled {
        pointer-events: none;
        color: $crayola;
      }
    }

    .phone-fields {
      border: 1px solid $gray-light-2;
      border-radius: $border-radius-sm;
      input {
        height: 46px;
        margin-bottom: 0;

        &:disabled {
          border-radius: 0;
        }
      }
    }

    .dropdown {
      .selected-country-flag {
        position: absolute;
        top: 16px;
        left: 10px;

        & + input {
          padding-left: $spacer * 3.3;
        }
      }

      input.form-control {
        text-align: left;
        cursor: pointer;

        &:enabled {
          background-color: $white;
        }
      }

      .dropdown-menu {
        padding: $spacer * 1.5;
        min-width: 308px;
        height: 380px;
        overflow-y: scroll;
        cursor: pointer;

        .country-phone-code {
          white-space: normal;
          width: auto;
          padding: $spacer * 0.3;
        }

        .flag-icon {
          min-width: $spacer * 1.7;
        }

        .country-name {
          color: $blue-charcoal;
          padding: $spacer * 0.3 $spacer * 0.2 0 $spacer;
        }
      }
    }
  }
}

.user-projects {
  &.nav-tabs {
    .nav-link {
      color: $gray-light-9;
      background-color: initial;

      &.active {
        @extend .text-primary;
      }
    }
  }
}
