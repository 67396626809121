.main-area {
  z-index: 1;
  background-size: cover;
  box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.3);
  background-image: url('/assets/images/welcome-background.jpg');

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0.5;
    background: $black;
  }

  .social-btn {
    a {
      height: 35px;
      width: 35px;

      &:hover {
        background: $gray-light-5;
      }
    }
  }
}

//HubSpot styling
.hubspot-form {
  .hs-input {
    &:not([type='checkbox']) {
      @extend .form-control;
    }

    &[type='checkbox'] {
      @extend .form-check-input, .bg-white;
    }
  }

  .hs-form-booleancheckbox {
    @extend .form-check;
    margin-bottom: $spacer * 1.6;

    &-display {
      span {
        @extend .ms-0;
        font-size: initial;
      }
    }
  }

  .hs-dependent-field {
    .inputs-list {
      padding: 0;
      margin: 0;
    }
  }

  .hs-form-field {
    @extend .form-group;

    label {
      @extend .form-label;
      margin-bottom: 0;
      line-height: initial;
    }
  }

  .hs-error-msgs.inputs-list {
    @extend .text-danger, .text-end;
    list-style: none;
    padding: 0;
    margin: 0;

    .hs-error-msg {
      margin-bottom: 0;
    }
  }

  .hs-button {
    @extend .btn, .btn-primary;
  }

  .submitted-message {
    @extend .alert, .alert-success;
  }
}
