// todo subject to style-guide changes
@import 'abstracts/variables';
@import 'abstracts/mixins';

.content-webclipping {
  ::ng-deep .webclip-content {
    img {
      max-width: 100%;
    }
  }

  img {
    margin-bottom: $spacer * 1.8;
  }

  figure {
    margin-bottom: $spacer * 1.8;
    position: relative;
    display: inline-block;

    img {
      max-width: 100%;
      vertical-align: top;
      margin-bottom: 0;
    }

    figcaption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: $spacer;
      background: rgba(0, 0, 0, 0.5);
      color: $white;
      font-size: 12px;
    }
  }

  iframe {
    max-width: 100%;
    margin-bottom: $spacer * 1.8;
  }

  .webclip {
    &-title {
      font-weight: bold;
    }

    &-author {
      color: $gray-light-10;
    }

    &-discussion {
      margin-top: $spacer * 3;

      h4 {
        font-weight: 500;
      }

      div {
        border: 1px dotted #ccc;
        margin: $spacer 0;
        padding: $spacer * 2;
        background-color: $white;

        span {
          color: $gray-light-10;
        }
      }
    }

    &-product {
      border: 1px solid $gray-dark-7;
      background-color: $white;
      margin-bottom: $spacer * 1.5;

      img {
        width: 160px;
        margin: $spacer;
      }

      .product-detail {
        margin: $spacer;

        .product-header {
          h4 {
            font-weight: bold;
          }

          h5 {
            font-weight: 500;
          }
        }

        .product-description {
          margin-top: $spacer * 3;

          p {
            text-align: justify;
          }
        }
      }

      .price-detail {
        border-top: 1px dashed $gray-dark-7;
        margin: $spacer;
        padding-top: $spacer;

        div {
          span {
            &:first-child {
              font-weight: bold;
              margin-right: $spacer * 0.5;
            }
          }
        }

        #actual-price {
          @include font-type(type-33);
        }

        .price-color {
          color: darkred;
        }
      }
    }

    &-video {
      figure {
        display: block;
      }

      iframe {
        width: 100%;
        height: 480px;
      }
    }
  }

  .carousel {
    margin-top: $spacer * 2;
  }

  .item {
    .thumb {
      width: 20%;
      margin: auto 2.5%;
      cursor: pointer;
      float: left;
    }

    img {
      width: 100%;
      margin: $spacer * 0.2;
    }
  }

  .meta-panel {
    padding: $spacer;

    .row {
      &:not(:last-child) {
        margin-bottom: $spacer * 4;
      }

      .meta-property {
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        @include font-type(type-32);
        color: $white;
        display: inline-block;
        border-radius: $spacer * 0.4;
        margin-bottom: $spacer * 0.48;
        margin-right: $spacer * 0.8;
        padding: $spacer * 0.8;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;

        &.label-secondary {
          background-color: $charcoal;
          border-color: $charcoal;
        }
      }
    }

    @media screen and (min-width: 992px) and (max-width: 1496px), (max-width: 767px) {
      .row {
        display: block;
      }
    }

    @media screen and (max-width: 425px) {
      padding: $spacer 0;
    }
  }
}
