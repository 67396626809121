ul.dropdown-menu.popup-menu {
  left: auto;
  right: 4px;

  @include media-breakpoint-down(md) {
    padding: $spacer * 1.5;
  }

  @include media-breakpoint-down(md) {
    li.my-account_options {
      border-bottom: none;
      padding: 0 $spacer * 1.5;
    }
  }

  &.mobile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    border-radius: 0;
  }

  i.icon.icon-chevron-right.back-button {
    transform: rotate(180deg);
  }
}
