.breadcrumb {
  margin-top: $spacer * 2.5;
  margin-left: $spacer * 5;

  @include media-breakpoint-down(sm) {
    margin-left: $spacer * 2;
  }

  &-item {
    .active {
      color: $gray-dark-9;
    }
  }
  &-item + &-item::before {
    color: $gray-light-12;
  }

  a {
    text-decoration: none;
    color: $gray-dark-8;
  }
}
