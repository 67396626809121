.spinner {
  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
  }

  .moon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: relative;
    box-shadow: inset 6px 0px, inset 6px 0px 1px 2px;
    color: $dark-green-color;
    animation: spin-right 4s linear infinite;

    &:before,
    &:after {
      content: '';
    }

    &:before,
    &:after,
    .core {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 100%;
    }

    &:before {
      width: 80%;
      height: 80%;
      margin-left: $spacer;
      box-shadow: inset 6px 0px, inset 6px 0px 1px 2px;
      color: $spinner-orange-color;
      animation: spin-left 2s linear infinite;
    }

    .core {
      width: 50%;
      height: 50%;
      margin-left: 18px;
      box-shadow: inset 4px 0px, inset 4px 0px 1px 2px;
      color: $dark-green-color;
      animation: spin-right 6s linear infinite;
    }
  }

  @keyframes spin-left {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @keyframes spin-right {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}
