@import 'abstracts/variables';

.evidences-list-item {
  cursor: pointer;

  .evidence {
    &-card {
      border: 1px solid $gray-light-2;
      border-radius: $border-radius-lg;
    }

    &-name {
      @include text-ellipsis($line-clamp: 2, $line-height: 19px);
      margin-top: $spacer * 1.7;
    }

    &-banner {
      // Safari Fix
      &.safari-fix-wrapper img {
        width: 100%;
        border-radius: $border-radius-lg $border-radius-lg 0 0;
      }
    }

    &-data {
      padding: $spacer * 2.3 $spacer * 2.2 $spacer * 1.6;
    }

    &-type {
      @include evidence-type();
    }

    &-share {
      @include floating-pills;
      height: 26px;
      width: 26px;
      right: 20px;
      line-height: 34px;
      background-color: $bondi-blue;
    }
  }
}

.evidence-owner {
  margin-top: $spacer * 2.1;

  &_image {
    @include user-image-style(36px, 36px, 50%);
  }

  &_name {
    @include font-type(type-22);
    margin-left: $spacer * 0.8;
  }

  .heart-loader {
    margin-top: $spacer * 0.32;
    animation: rotate 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;

    &-container {
      margin-right: $spacer * 1.44;
      margin-left: $spacer * 1.44;
    }
  }

  .heart-loader span {
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    background: $pink-color;
    border: 1px solid $pink-color;
    border-radius: 100%;
  }

  .heart-loader span:nth-child(1) {
    transform: translate(-5px, -5px);
    animation: heart-shape_1 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }

  .heart-loader span:nth-child(2) {
    transform: translate(5px, -5px);
    animation: heart-shape_2 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }

  .heart-loader span:nth-child(3) {
    position: relative;
    border-radius: 0px;
    transform: scale(0.98) rotate(-45deg);
    animation: heart-shape_3 2.3s cubic-bezier(0.75, 0, 0.5, 1) infinite;
  }
}

@keyframes rotate {
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

@keyframes heart-shape_1 {
  60% {
    transform: scale(0.4);
  }
}

@keyframes heart-shape_2 {
  40% {
    transform: scale(0.4);
  }
}

@keyframes heart-shape_3 {
  50% {
    border-radius: 100%;
    transform: scale(0.5) rotate(-45deg);
  }
  100% {
    transform: scale(0.98) rotate(-45deg);
  }
}

.project-evidence-single {
  .evidence-controls {
    background-color: $white;
    padding: $spacer * 1.7 $spacer * 3;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .evidence-type {
      @include evidence-type();
    }

    .control-buttons {
      button:not(:last-child) {
        margin-right: $spacer;
      }
    }
  }

  .evidence {
    &-content {
      .content-wrapper {
        background-color: $gray-light-15;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .spinner {
          position: relative;
          margin-top: $spacer * 7.5;
          margin-bottom: $spacer * 15;
        }

        .fileupload {
          width: 80%;
          margin: 0 auto;

          .type-email {
            background-color: $white;
            padding: $spacer * 3.7;

            .email-content {
              border-top: 2px solid $gray-light-2;
              padding-top: $spacer * 3;
            }
          }

          .type-freetext {
            background-color: $white;
            padding: $spacer * 3.7;
            text-align: left;
            white-space: pre-line;

            .free-text-heading {
              @include font-type(type-18);
              color: $evidence-dark-green;
              margin-bottom: $spacer * 2;
            }
          }
        }
      }
    }
  }
}
