/**
 * Font imports
 * 100 = hairline, 200 = thin, 300 = light, 400 = regular, 500 = medium, 600 = semi-bold, 700 = bold, 800 = heavy, 900 = black
 */
$font-import: () !default;

/**
 * CSS Font family names with websafe fallback
 * usage:
 * font-family: font-family-type(primary)
 * font: 12px/24px font-family-type(secondary)
 */
$font-family-type: () !default;

/**
  * Font types, define font styling, optionally combined with respond breakpoints
  * retrieve font style with the mixin: font-type($name)
  * usage:
  * @include font-type(primary)
  */
$font-types: () !default;

/**
 * Import defined fonts
 */
@each $font, $settings in $font-import {
  @each $name, $format in $settings {
    @include font-import(
      $font,
      '/assets/fonts/#{$font}/#{$font}-#{$name}',
      map_get($format, 'weight'),
      map_get($format, 'style')
    );
  }
}

/**
 * Output fonts
 */
@each $name, $value in $font-family-type {
  .font-family-#{$name} {
    font-family: font-family-type($name) !important;
  }
}

/**
* Output types
*/
@each $name, $value in $font-types {
  .font-type-#{$name} {
    @include font-type($name);
  }
}
