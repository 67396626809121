.keywords-title {
  @include font-type(type-27);
  padding-bottom: $spacer * 1.2;
}

.keyword-item {
  @include font-type(type-16);
  padding: $spacer * 1.2 $spacer * 2;
  margin-right: $spacer;
  margin-bottom: $spacer;
  border-radius: $border-radius-sm;
  background-color: $gray-light-8;
}
