.upload-file {
  &_wrapper {
    button {
      @extend .btn;
      @extend .btn-upload;

      height: $input-height;
      float: right;
    }

    input {
      &[type='file'] {
        display: none;
      }
    }
  }

  &_name-display {
    @include input-style;
    line-height: 2.5;
  }
}
