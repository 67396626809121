$lead-label-color: #197ac1;
$right-side-avatar-size: 42px;

.spinner {
  position: relative;
  margin-top: $spacer * 17.5;
  margin-bottom: $spacer * 1.5;
}

.project {
  padding: $spacer * 6 $spacer * 5;

  &-container {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 8;

    .card {
      &-header {
        @include font-type(project-sections-headers);
        color: $primary;
        padding-bottom: $spacer * 1.6;
      }
    }
  }

  &-sidebar {
    padding-right: $spacer * 1.5;
  }

  &-body {
    padding-left: $spacer * 1.5;

    .lead-organization-color {
      height: 30px;
    }

    .content {
      margin-top: $spacer * 2;
    }

    .inner-nav {
      padding: $spacer * 1.6 $card-padding-default;
      margin-bottom: $spacer * 2;
      background-color: $white;
      border-radius: $card-border-radius;

      a {
        @include font-type(type-25);
        padding: 0 $spacer;
        text-transform: none;
        color: $gray-dark-5;

        &:first-child {
          padding-left: 0;
        }

        &.active {
          color: theme-color(primary);
        }
      }

      .project-share {
        height: 31px;
        width: 31px;
        padding: $spacer * 0.8;
        background-color: $bondi-blue;
      }
    }

    .card-content {
      h5 {
        margin-top: $spacer;
        margin-bottom: $spacer * 3.2;
        color: $primary;
        line-height: 1.5;
        cursor: pointer;
      }
    }
  }

  &-name {
    line-height: 40px;
    margin-top: $spacer * 1.2;
  }

  &-banner {
    height: 436px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.card {
      margin-bottom: 0;
    }

    .liked-project {
      height: 0;
      position: relative;
      right: 28px;
      bottom: 75px;
      z-index: 2;
      box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.13);
      border-radius: 7px;

      @media (max-width: 576px) {
        right: 28px;
        bottom: 75px;
      }
    }

    .project {
      &-badge {
        @include font-type(type-6);
        padding: $spacer * 1.2 $spacer * 1.6;
        margin-left: $spacer * 3;
        margin-top: $spacer * 3;
        width: 113px;
        display: inline-flex;
        text-transform: uppercase;
        border-radius: $border-radius-xs;
        background-color: theme-color(secondary);

        .icon {
          margin-right: $spacer * 0.4;
        }
      }

      &-info {
        &_row {
          width: 100%;
          margin-bottom: $spacer * 0.8;
          overflow: hidden;
          margin-right: 0;

          .project-name {
            @include text-ellipsis($line-clamp: 2, $line-height: 32px);
            margin-top: $spacer * 0.5;
            padding-top: $spacer * 0.7;
          }

          .followers-section {
            .btn {
              width: auto;
              min-width: 173px;
              z-index: 2;

              &.btn-following {
                color: $charcoal;

                .icon {
                  margin-right: $spacer * 0.6;
                  position: relative;
                  bottom: $spacer * 0.2;
                }
              }
            }
          }
        }
      }

      &-followers {
        @include font-type(type-8);
        color: $charcoal;
        margin-right: $spacer * 1.9;
      }
    }

    .info-bit .project {
      &-date-range {
        @include font-type(type-9);
      }
    }

    .card-body {
      background-color: rgba(255, 255, 255, 0.88);
      padding-top: $spacer * 2.4;
      padding-bottom: $spacer * 2;

      &::before {
        z-index: 1;
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
      }

      & > div {
        z-index: 2;
      }
    }
  }

  &-team {
    .project-owner {
      .user-avatars_default-avatar {
        width: 90px;
        height: 90px;
        margin-bottom: $spacer * 1.5;
        @include font-type(type-2);
      }
      .user-avatars_image {
        width: 90px;
        height: 90px;
        margin-bottom: $spacer * 1.5;
      }
    }
    .card {
      &-header {
        &:first-child {
          padding-top: $spacer * 1.9;
        }
      }

      &-body {
        .owner {
          &-avatar {
            width: 90px;
            height: 90px;
            margin-bottom: $spacer * 1.5;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          &-name {
            @include font-type(type-19);
          }

          &-title {
            @include font-type(type-8);
            padding-bottom: $spacer * 2.5;
          }
        }

        .social-links {
          a {
            height: 36px;
            width: 36px;
            background-color: $bright-gray;
          }
        }
      }

      &-footer {
        padding-top: $card-padding-default;

        .team-heading {
          @include font-type(type-22);
          color: $charcoal;
          text-transform: uppercase;
          margin-bottom: $spacer * 2.6;
        }

        .team-container {
          &:hover {
            cursor: pointer;
          }

          .team-avatars-section {
            min-width: 120px;
          }
        }

        .collaboration-approved {
          margin-top: $spacer * 4;

          i {
            margin-right: $spacer * 0.6;
          }

          span {
            padding-top: $spacer * 0.4;
          }
        }

        button {
          margin-top: $spacer * 2.4;
          color: $white;
        }
      }
    }

    .user-avatars_title {
      @include font-type(team-heading);
      text-transform: uppercase;
      color: $charcoal;
    }
  }

  &-overview {
    .description {
      padding-bottom: $card-padding-default;
      white-space: pre-line;

      .short-description {
        @include font-type(project-short-description);
        display: block;
        height: 135px;
        overflow: hidden;
        margin-bottom: $spacer * 2;

        &.collapse.show {
          height: 100%;
        }
      }

      .keywords {
        border-top: 1px solid $bright-gray;
        &-title {
          @include font-type(type-7);
          padding: $spacer * 1.5 0;
        }
      }
    }

    .details,
    .funding {
      border-top: 1px solid $bright-gray;
    }

    .details {
      $detail-item-height: 40px;

      &-title,
      &-section {
        margin-top: $spacer * 3;
      }

      .card-title-small {
        margin-bottom: $spacer * 1.6;
      }

      .sdg {
        &-id {
          @include font-type(type-19);
          margin-bottom: $spacer * 0.6;
        }

        &-description {
          @include font-type(type-13);
        }
      }

      .impact-area_icon:nth-child(4n) {
        margin-right: $spacer;
      }
    }

    .funding {
      padding-top: $spacer * 1.3;
      padding-bottom: $spacer;

      &-section {
        padding-top: $spacer * 2;
      }
    }
  }

  &-organizations {
    .card {
      &-body {
        padding-top: 0;
        padding-bottom: 0;
      }

      &-content {
        &.collaborating-organizations {
          padding-top: 0;
        }

        .lead-organization {
          .lead-label {
            margin-left: 0;
            width: 42px;
          }

          .name {
            word-break: break-word;
          }
        }
      }

      &-content-border {
        border-radius: 6px;
      }

      &-footer {
        padding-bottom: 0;
      }

      &-header:first-child {
        padding-top: $spacer * 1.9;
        padding-bottom: $spacer * 1.9;
      }
    }

    &,
    &-details {
      .organization {
        &-avatar {
          width: 100%;
          max-width: 79px;
          height: 77px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: $border-radius-xl;
        }

        &-info {
          margin-left: $spacer * 1.6;

          .lead-label {
            width: 42px;
          }

          .name {
            margin-bottom: $spacer;
          }

          .country-code.projects-count {
            color: $sonic-silver;

            i {
              color: $gray-light-3;
              font-style: normal;
              padding-right: $spacer * 0.7;

              &.ps-custom {
                padding-left: $spacer * 0.7;
              }
            }
          }
        }
      }
    }

    &-details {
      .collaborating-organizations {
        margin-top: $spacer * 5;

        .sub-title {
          @include font-type(type-20);
          padding-bottom: $spacer * 3.3;
        }
      }

      .organization-avatar {
        max-width: 111px;
        height: 108px;
      }
    }
  }

  &-sdgs {
    .card-body {
      padding-bottom: $spacer * 1.8;
      padding-right: $spacer * 1.8;

      .sdg {
        width: 75px;
        height: 75px;
        margin: 0 $spacer * 1.2 $spacer * 1.2 0;
      }
    }
  }

  &-headlines,
  &-outputs {
    .card-image {
      width: $right-side-avatar-size;

      @include square-image($right-side-avatar-size);
    }

    .card-body {
      .item {
        &-data {
          margin-left: $spacer * 1.2;
        }
        &:not(:first-child) {
          border-top: 1px solid $gray-light-2;
          padding-top: inherit;
        }
        &:not(:last-child) {
          padding-bottom: inherit;
        }

        .details {
          margin-top: $spacer * 0.7;

          .impact {
            &-area {
              padding-right: $spacer * 0.9;
              border-right: 1px solid $gray-light-5;
            }
          }

          .date {
            padding-left: $spacer * 0.9;
            color: $text-muted;

            .icon {
              opacity: 0.4;
              margin-right: $spacer * 0.3;
            }
          }
        }

        .indicator-description {
          @include text-ellipsis($line-clamp: 3, $line-height: 15px);
        }
      }
    }
  }

  &-summary {
    .card-body {
      padding: $card-padding-default $spacer * 1.4;
    }

    &-col {
      border-right: 1px solid $bright-gray;

      &:nth-child(3) {
        border: none;
      }

      @include media-breakpoint-down(xxl) {
        border: none;
      }
    }

    &-label {
      color: $sonic-silver;
      display: inline-block;
      padding-bottom: $spacer * 1.5;
    }
  }

  &-milestones,
  &-milestones-details {
    .card-title {
      padding-top: $spacer * 1.6;
    }

    .milestone-item {
      .milestone-info {
        margin-left: $spacer * 0.9;

        .date {
          padding-left: $spacer * 0.9;
        }
      }
    }
  }

  &-status {
    &.card-header {
      padding: $spacer * 1.3 $card-padding-default $spacer * 1.1;
    }

    .project-stage {
      @include font-type(type-7);
      padding: $spacer * 0.9 $spacer * 1.5;
      text-transform: uppercase;

      &.active {
        color: $green-600;
        background-color: $green;
      }
      &.inactive {
        color: $gray-dark-6;
        background-color: $gray-light-4;
      }
    }
  }

  &-container {
    .gauge-container {
      height: 100px;
      width: 100px;

      .progress-percent {
        @include font-style(type-10);
        text-align: center;
        position: relative;
        right: 50%;
      }
    }
  }

  // Project Team Veiw All Team Members/Collaborators/Previous Contributors
  &-team-details {
    .team-details {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-light-6;
      }

      &.collaborators,
      &.previous-contributors {
        padding-top: $spacer * 2;
      }

      .team-details-heading {
        margin-bottom: $spacer * 2;

        h5 {
          margin-bottom: $spacer;
        }

        .team-details-count {
          font-size: 14px;
        }
      }

      .team-member {
        &:not(:last-child) {
          margin-bottom: $spacer * 3;
        }

        p {
          margin-bottom: 0;
        }

        .user-avatars_image {
          @include user-image-style(60px, 60px, 50%);
          @include user-avatar-default-style(60px, 60px, 50%);
        }

        .user-avatars_default-avatar {
          @include user-image-style(60px, 60px, 50%);
          @include user-avatar-default-style(60px, 60px, 50%);
          margin-bottom: 0;
          padding-top: 0;

          &[title] {
            padding-top: $spacer * 0.2;
            @include font-type(type-2);
          }
        }

        .username {
          @include font-type(type-25);
        }

        .user-details {
          color: $gray-dark;
        }
      }

      i {
        color: $gray-light-3;
        font-style: normal;
        padding-right: $spacer * 0.7;
        padding-left: $spacer * 0.7;
      }
    }
  }
}

.lead-label {
  @include font-type(type-6);
  bottom: 8px;
  padding: $spacer * 0.4 $spacer * 0.8;
  margin-left: $spacer * 0.9;
  line-height: 1;
  position: relative;
  text-transform: uppercase;
  color: $lead-label-color;
  border: 1px solid $lead-label-color;
  border-radius: $border-radius-sm;
}

// Evidences
.evidences {
  &-title {
    margin-top: $spacer * 4;
    margin-bottom: $spacer * 3.5;

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    .btn-upload-evidence {
      width: 192px;
    }
  }

  &-list {
    background-color: $white;
    padding: $spacer * 4 $card-padding-default;
    border-radius: $border-radius-ls;

    .spinner {
      position: relative;
      margin-top: $spacer * 1.5;
      margin-bottom: $spacer * 1.5;
    }

    &-item {
      cursor: pointer;
    }
  }

  &-single {
    .evidence-info {
      padding: $spacer * 3.2 $spacer * 3.1 $spacer * 3.2 $spacer * 2.9;
    }
  }
}

.upload-evidence-modal {
  padding: $spacer * 5;

  .title {
    @include font-type(type-29);
    margin-bottom: $spacer * 4.1;
  }

  .form-group {
    margin-bottom: $spacer * 2.3;
  }

  .form-select {
    max-width: 300px;
  }

  .btn-submit-evidence {
    width: 141px;
  }
}

/* Collaboration modal */
.modal-collaboration-container {
  max-width: 808px;
  margin: $spacer * 3 $spacer * 4.2;
  position: relative;

  @media (max-width: 576px) {
    margin: $spacer * 2;
  }

  .modal-collaboration-content {
    .btn-close {
      position: absolute;
      right: 8px;
      top: 10px;
    }

    .user-details {
      width: 55%;
      position: relative;
      border-right: 1px solid $gray-light-5;

      @media (max-width: 992px) {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid $gray-light-5;
      }

      .user-avatars {
        &_default-avatar {
          @include user-avatar-default-style(89px, 86px, 50%);

          &[title] {
            @include font-type(type-2);
          }
        }

        &_image {
          width: 70px;
          height: 70px;
        }
      }

      &-info {
        .user-name {
          margin-top: $spacer * 1.6;
          @include font-type(type-19);
        }

        &:not(:first-child) {
          @include font-type(type-8);
        }

        .user-title {
          margin-bottom: $spacer * 1.1;
        }

        .user-email {
          margin-bottom: $spacer * 0.4;
        }
      }

      .text {
        width: 70%;
        margin-top: $spacer * 6.5;
        @include font-type(type-8);

        @media (max-width: 992px) {
          width: 100%;
          margin-top: $spacer * 3;
          margin-bottom: $spacer * 2;
          text-align: center;
        }
      }
    }

    .info-box {
      padding-left: $spacer * 3.5;

      @media (max-width: 992px) {
        margin-top: $spacer * 2;
        padding-left: 0;
      }

      .modal-title {
        color: $primary;
      }

      .apply-btn {
        @include font-type(type-20);
        padding: $spacer * 1.2 $spacer * 4;
      }
    }

    .success-pop-up {
      margin: $spacer * 2.7 auto;

      .icons {
        position: relative;
        width: 62px;
        margin: 0 auto;

        .icon {
          &-green-tick {
            position: absolute;
            top: 19px;
            left: 55px;
          }
        }
      }

      h2 {
        color: $primary;
        margin-bottom: $spacer * 2;
        margin-top: $spacer * 2.7;
      }

      p {
        width: 85%;
        margin: 0 auto;
        @include font-type(type-5);

        @media (max-width: 992px) {
          width: 100%;
        }
      }
    }
  }
}
