footer {
  background-color: $body-color;
  position: absolute;
  bottom: 0;
  width: 100%;

  .copyright {
    @include font-type(type-7);
    color: $crayola-crystal;
    padding-left: $spacer * 4;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }

    @include media-breakpoint-down(xl) {
      padding-left: $spacer;
    }
  }

  .footer-link {
    @include font-type(type-7);
    color: $gray-light-3;
    text-decoration: none;

    &:first-child {
      margin-right: $spacer * 2.2;
    }
  }

  nav.navbar {
    padding: $spacer * 1.9 0 $spacer * 1.8;

    .navbar {
      &-nav .nav {
        &-link {
          @include font-type(type-26);
          color: $white;
          padding-right: $spacer * 4;

          @include media-breakpoint-down(sm) {
            padding-left: $spacer;
            padding-right: $spacer;
          }
        }
      }
    }
  }

  .external-links {
    padding-left: $spacer * 3.2;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }
    @include media-breakpoint-down(xl) {
      padding-left: 0;
    }
  }
}
