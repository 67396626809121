.social-buttons {
  .btn {
    min-width: 148px;
    text-transform: none;
    text-align: center;

    &-google,
    &-orchid {
      padding-top: $spacer * 1.5;
      padding-bottom: $spacer * 1.5;

      .text-google,
      .text-orchid {
        padding-top: $spacer * 0.4;
      }
    }

    &-linked-in {
      padding-top: $spacer * 1.4;
      padding-bottom: $spacer * 1.4;

      .text-linked-in {
        padding-top: $spacer * 0.6;
      }
    }
  }
}
