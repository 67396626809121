$wrapper-width: 585px;

.auth {
  &-container {
    height: 100%;
    min-height: 100vh;
    background-image: url('/assets/images/login-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: $spacer * 2.5;
    padding-bottom: $spacer * 6;

    .link-chat {
      margin-right: $spacer * 2;
      padding-right: $spacer * 2;
    }
  }

  &-form_wrapper {
    max-width: $wrapper-width;
    border-radius: $border-radius-md;
    padding: $spacer * 4.4;
  }

  &-sign_up_wrapper {
    border-radius: $border-radius-md;
    padding: $spacer;
    max-width: 795px;

    .form-wrapper {
      width: 290px;

      @media (min-width: 375px) {
        width: 541px;
      }

      .sign-up-form {
        .error-msg {
          height: 13px;
        }

        .recaptcha-field {
          transform: scale(0.7);
          transform-origin: 0;
        }

        .submit-btn {
          margin-bottom: $spacer * 2;
        }
      }
    }

    @media screen and (min-width: 576px) {
      padding: $spacer * 4.4;

      .form-wrapper {
        border-right: 1px solid $gray-light-2;
        padding-right: $spacer * 4.4;
      }
    }

    .sidebar-wrapper {
      width: 166px;
      text-align: center;
      padding-left: $spacer * 4;
      padding-top: $spacer * 0.5;

      &-info {
        padding: $spacer * 5.5 0;

        &:not(:last-child) {
          border-bottom: 1px solid $gray-light-2;
        }

        .icon {
          margin-bottom: 5px;
        }
      }
    }
  }
}
