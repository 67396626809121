$search-box-height: 620px;
$actions-container-height: 348px;
$action-block-width: 524px;

.search-box {
  &_container {
    min-height: $search-box-height;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/assets/images/search-box-background.jpg');
  }

  &_row {
    padding: $spacer * 4 $spacer;

    @include media-breakpoint-up(md) {
      padding: $spacer * 4 $spacer * 3.2;
      padding-top: $spacer * 9.3;
    }
  }

  &_wrapper {
    max-width: 1065px;
    min-height: 440px;
    border-radius: 8px;
    padding: $spacer * 4.6 $spacer * 9.1 $spacer * 6.3;
    background-color: $white-light;

    @include media-breakpoint-down(sm) {
      padding: $spacer * 3 $spacer * 2 $spacer * 3;
    }

    span.icon {
      height: 86px;
      width: 363px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('/assets/icons/svg/track-impact-home.svg');
    }
  }

  &_inputGroup {
    margin-bottom: $spacer * 2;

    @include media-breakpoint-down(sm) {
      margin-bottom: $spacer * 1;
    }

    input.form-control,
    button {
      height: 60px;
      border: 0;
    }

    input.form-control {
      @include font-type(type-5);
      padding: $spacer * 2.2 $spacer * 4.2;

      &::placeholder {
        @include font-type(type-8);

        @include media-breakpoint-up(sm) {
          @include font-type(type-5);
        }
      }

      & + .icon {
        top: 23px;
        left: 22px;
        z-index: 100;
        position: absolute;
      }
    }

    button {
      @include font-type(type-7);
      width: 169px;
      border-radius: 0 $border-radius-sm $border-radius-sm 0;

      @include media-breakpoint-down(md) {
        border-radius: $border-radius-sm;
      }
    }
  }

  &_slogan {
    @include font-type(slogan);
    color: theme-color(secondary);
    margin-top: $spacer;
    margin-bottom: $spacer * 3.2;
  }

  &_info {
    padding-top: $spacer * 2.4;
  }

  .keywords {
    &_title {
      max-width: 130px;
      margin-right: $spacer * 4.4;

      .icon {
        margin-right: $spacer * 0.8;
      }
    }

    &_keyword {
      @include font-type(type-8);
      margin: 0 $spacer * 0.4;
      padding: $spacer * 0.8 $spacer * 1.8;
      color: theme-color(primary);
      line-height: 1;
      cursor: pointer;
      overflow: hidden;
      border-radius: 15px;
      border: 1px solid theme-color(primary);
      background-color: transparent;

      @include media-breakpoint-down(sm) {
        width: 100px;
      }

      &:hover,
      &:active,
      &:focus {
        color: theme-color(primary);
        border-color: theme-color(primary);
        background-color: $white;
      }

      &--text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.homepage {
  padding-bottom: $spacer * 7.76;

  @media screen and (max-width: 1367px) and (min-width: 1200px), (max-width: 1017px) {
    padding-bottom: $spacer * 9.6;
  }

  @media (max-width: 768px) {
    padding-bottom: $spacer * 12.56;
  }

  .sdg-card {
    &_wrapper {
      @include media-breakpoint-up(sm) {
        margin: $spacer * 7 0 $spacer * 11 0;
      }
    }

    &_container {
      @include media-breakpoint-up(sm) {
        min-width: 477px;
        min-height: 175px;
      }

      .card-header {
        color: $cinnabar;
        padding: $spacer * 2 $spacer * 3;

        @include media-breakpoint-up(sm) {
          @include font-type(sdg-counter-header);
        }
      }

      .card-body {
        @include media-breakpoint-up(sm) {
          padding: $spacer * 3 $spacer * 5.6;

          .label {
            @include font-type(type-24);
          }
        }

        .count {
          line-height: 24px;
          color: $cinnabar;

          @include media-breakpoint-up(sm) {
            @include font-type(sdg-counter-time);
          }
        }
      }
    }
  }

  .headlines {
    &-container {
      margin-top: $spacer * 6.6;
      margin-bottom: $spacer * 7.8;

      h3 {
        margin-bottom: $spacer * 1.8;
      }
    }

    &-wrapper {
      padding: $spacer * 2.9 $spacer * 2.2 $spacer * 2.3;
      cursor: pointer;
    }

    &-body {
      padding-left: $spacer * 1.6;
      word-break: break-word;

      &_description {
        @include font-type(headline-description);
        @include text-ellipsis($line-clamp: 3, $line-height: 22px);
        margin-bottom: $spacer * 2;
        color: $body-color;
        padding-right: $spacer * 3.5;

        @include media-breakpoint-down(sm) {
          padding-right: $spacer * 2;
        }
      }

      &_details {
        .sdg {
          height: 30px;
          width: 30px;
          margin-right: $spacer * 0.5;
        }

        .excess-items {
          width: 20px;
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column-reverse;

          .empty {
            height: 30px;
          }
        }

        @include media-breakpoint-between(lg, xl) {
          flex-direction: column-reverse;

          .empty {
            height: 30px;
          }
        }
      }
    }

    &-col {
      &:nth-child(odd) {
        padding-left: 0;
        padding-right: $spacer;
      }

      &:nth-child(even) {
        padding-right: 0;
        padding-left: $spacer;
      }

      @include media-breakpoint-down(lg) {
        &:nth-child(odd) {
          padding-right: 0;
        }

        &:nth-child(even) {
          padding-left: 0;
        }
      }

      .card {
        border-color: transparent;
        border-radius: $border-radius-ls;

        &-image {
          width: 59px;
          padding-top: $spacer * 0.7;

          @include square-image(59px);
        }

        &-title {
          @include font-type(type-28);
          @include text-ellipsis($line-clamp: 2, $line-height: 24px);
          margin-bottom: $spacer * 1.2;
          padding-right: $spacer * 3.5;

          @include media-breakpoint-down(sm) {
            padding-right: $spacer * 2;
          }
        }

        &-text {
          &span {
            display: inline-block;
          }

          &.details {
            margin-top: $spacer * 1.6;

            .impact {
              &-area {
                padding-right: $spacer * 0.9;
                border-right: 1px solid $gray-light-5;
              }

              &-date {
                padding-left: $spacer * 0.9;
                color: $text-muted;

                .icon {
                  opacity: 0.4;
                  margin-right: $spacer * 0.3;
                }
              }
            }
          }
        }
      }
    }
  }

  .actions_container {
    padding: $spacer * 2 0 $spacer * 2.5;
    margin-bottom: $spacer * 4.4;
    border-radius: $border-radius-ls;
    min-height: $actions-container-height;

    @include media-breakpoint-up(md) {
      padding: $spacer * 6 0 $spacer * 5.2;
      margin-bottom: $spacer * 8;
    }

    .actions-label {
      @include font-type(cta-header);
      margin-top: 0;

      @include media-breakpoint-up(md) {
        @include font-type(type-15);
        margin-bottom: $spacer * 2.6;
      }
    }

    .action-copy {
      @include font-type(type-7);

      @include media-breakpoint-up(md) {
        @include font-type(default);
      }
    }

    .discover-project_col,
    .sign-up_col {
      padding-left: $spacer * 2.2;
      padding-right: $spacer * 2.2;
    }

    .discover-project_col {
      padding-bottom: $spacer * 1.7;

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }
    }

    .collaborate_wrapper,
    .create_project_wrapper {
      max-width: $action-block-width;
      padding: $spacer * 2 $spacer * 1.9 $spacer * 1.5;
      border-radius: $border-radius-ls;
      border: 2px solid $gray-light-13;

      @include media-breakpoint-up(md) {
        padding: $spacer * 5 $spacer * 3;
      }

      .header {
        padding: 0 $spacer * 1.2;
        top: -12px;
        border-radius: $border-radius-lg;

        @include media-breakpoint-up(sm) {
          padding: 0 $spacer * 4.4;
        }
      }
    }
  }
}
