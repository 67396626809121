.toast-wrapper {
  position: fixed;
  right: 25px;
  width: 277px;
  z-index: 1;

  @media (min-width: 375px) {
    right: 50px;
  }

  @media (min-width: 425px) {
    width: 329px;
  }

  .toast {
    margin-bottom: $spacer * 0.8;
    display: grid;
    padding: $spacer * 2;
    border-radius: $card-border-radius;
    border: 1px solid $charcoal;
  }

  .icon {
    margin: 0 auto;
    padding-bottom: 0;
  }

  .text {
    @include font-type(type-11);
    margin-top: $spacer * 1.5;
  }
}
