/**
 * CSS Font family names
 */
$font-family-type: (
  primary: (
    lato,
    'Times New Roman',
    Times,
    serif
  )
) !default;

/**
* Font map
* Mapping of all the fonts used
*/
$font-types: (
  primary: (
    default: (
      font-family: primary,
      font-size: 1em,
      line-height: normal,
      letter-spacing: normal
    )
  )
) !default;

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

/**
* Return a font-family.
* @param {string} $name The font family name
*/
@function font-family-type($name: primary) {
  @if map_has_key($font-family-type, $name) {
    @return map_get($font-family-type, $name);
  } @else {
    @return map_get($font-family-type, primary);
  }
}
