@charset "UTF-8";

// 1. Configuration and helpers
@import 'abstracts/mixins', 'abstracts/functions', 'abstracts/variables', 'abstracts/utilities';

// 2. Vendors
@import 'vendors/bootstrap', 'vendors/syncfusion';

// 3. Base stuff
@import 'base/base', 'base/typography';

// 4. Components
@import 'components/button', 'components/alert', 'components/card', 'components/select',
  'components/content-webclipping', 'components/project-card', 'components/badge',
  'components/dropdown-menu', 'components/side-nav', 'components/upload-file';

// 5. Layout styles
@import 'layout/forms', 'layout/header', 'layout/footer', 'layout/user-avatars',
  'layout/impact-area', 'layout/sdg', 'layout/single-evidence', 'layout/keywords',
  'layout/breadcrumb', 'layout/social-buttons', 'layout/toast', 'layout/navigation',
  'layout/nav-account', 'layout/search-result-top-bar', 'layout/spinner', 'layout/project-likes',
  'layout/private-top-navbar', 'layout/maintenance';
// 6. Page-specific styles
@import 'pages/auth', 'pages/project', 'pages/home', 'pages/account', 'pages/welcome', 'pages/legal';

// 7. Sprites
@import 'sprites/sprite';

// 8. Overrides
@import 'bootstrapOverrides', '_paginatorOverrides.scss';

// flag-icon-css npm module
@import '~flag-icon-css/sass/flag-icon';

.main {
  min-height: 100vh;
}
