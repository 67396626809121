.paginator_container .p-paginator {
  .p-paginator-pages .p-paginator-page {
    border-radius: $card-border-radius;

    &.p-highlight {
      background: $primary;
      border-color: $primary;
      color: $white;
    }
  }
}
