@import 'abstracts/variables', 'abstracts/mixins', 'bootstrap-utilities';

.project {
  &-badge {
    @include font-type(type-6);
    display: inline-flex;
    padding: $spacer * 0.9;
    line-height: 1;
    margin-right: $spacer;
    color: $white;
    text-transform: uppercase;
    border-radius: $border-radius-xs;
    background-color: theme-color(secondary);

    .icon {
      margin-right: $spacer * 0.3;
    }

    &_empty {
      min-height: 29px;
    }
  }

  &-date-range {
    @include font-type(type-26);
    color: $sonic-silver;
    min-height: 18px;
  }

  &-card {
    border: none;
    border-radius: $border-radius-md;
    transition: transform 0.2s;

    &_banner {
      height: 147px;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 6px 6px 0 0;
    }

    &:hover {
      transform: scale(1.02);
    }

    &_details {
      min-height: 73px;

      .keywords_wrapper {
        width: auto;
        min-width: 215px;
        max-width: 265px;
        margin-right: $spacer * 3;
      }

      .impact-area_wrapper {
        min-width: 154px;
        min-height: 78px;
      }

      .project-followers_wrapper {
        min-width: 102px;
      }
    }

    .card-buttons {
      padding-top: $spacer * 2.3;
      padding-left: 0;

      .btn {
        height: 50px;
        width: 100%;
        line-height: 2em;

        &-outline-primary {
          // commented cod for release
          // margin-right: $spacer;
        }

        &-following {
          padding: $spacer * 1.9 $spacer;
          color: $body-color;
          pointer-events: none;

          &.user-followed-projects {
            pointer-events: all;
          }

          .unfollowing {
            display: none;
          }

          &:hover {
            .following {
              display: none !important;
            }

            .unfollowing {
              display: flex;
            }
          }

          span {
            margin-left: $spacer * 0.5;
          }

          i {
            position: relative;
            bottom: 2px;
          }
        }
      }

      &.list-view {
        padding-left: $spacer * 6;

        .btn-outline-primary {
          // commented cod for release
          // margin-right: $spacer;
          width: 100%;
          //width: 56%;
        }

        .btn-follow-project,
        .btn-following {
          width: 44%;
        }
      }
    }

    @include media-breakpoint-down(xl) {
      .card-buttons,
      .card-buttons.list-view {
        padding-left: 0;

        .btn {
          &-outline-primary,
          &-follow-project,
          &-following {
            width: 50%;
          }
        }
      }
    }

    &_body {
      padding: $spacer * 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &_header {
      line-height: $line-height-xl;

      .badge_wrapper {
        padding-bottom: $spacer * 1.2;
      }

      &.list-view .badge_wrapper {
        padding-bottom: $spacer * 0.4;
      }
    }

    &_title {
      @include text-ellipsis($line-clamp: 3, $line-height: 24px);
      margin-bottom: $spacer * 2;

      &.list-view {
        margin-top: $spacer * 0.9;
        margin-bottom: $spacer;
      }
    }

    &_description {
      @include font-type(type-18);
      @include text-ellipsis($line-clamp: 3, $line-height: 24px);
      margin-bottom: $spacer * 2.5;
    }

    .liked-project {
      border-radius: 7px;
      box-shadow: 1px 1px 3px rgb(0, 0, 0, 0.13);
    }

    .btn {
      @include font-type(type-20);
      line-height: 2;
      padding: $spacer * 1.3;

      &-liked-project {
        @include font-type(type-35);
        padding: $spacer * 0.9 $spacer * 5.3 $spacer * 0.8;
      }
    }

    .excess-items {
      padding-top: $spacer;
    }
  }
}

// Collaboration request statuses
.cr {
  padding: $spacer * 0.8;
  border-radius: 3px;
  width: 86px;

  &-0 {
    background-color: $evidence-purple;
  }

  &-1 {
    background-color: $green-light;
  }
}
