.impact-area {
  &_wrapper {
    margin-bottom: $spacer * 3.5;
  }

  &_icon {
    width: 45px;
    height: 40px;
    padding: $spacer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-md;
    margin-right: $spacer;
    margin-bottom: $spacer;
    background-color: $gray-light-8;

    &:nth-child(1n + 5) {
      margin-bottom: 0;
    }
  }

  &_title {
    @include font-type(type-27);
    text-transform: uppercase;
    margin-bottom: $spacer * 1.6;
  }
}
