.sdg {
  width: 79px;
  height: 79px;
  padding: $spacer * 0.6 $spacer;

  input[type='checkbox'] {
    height: 13px;
    margin-bottom: 0;
    padding: 0;
  }

  &-id {
    @include font-type(type-12);
    margin-bottom: $spacer;
  }

  &-description {
    @include font-type(type-21);
    text-transform: uppercase;
    display: block;
  }

  @each $sdg-item in $sdg-images {
    $sdg-id: nth($sdg-item, 1);
    $sdg-color: nth($sdg-item, 2);

    &.sdg-#{$sdg-id} {
      background: url('/assets/images/sdg/E-WEB-Goal-#{$sdg-id}.svg');
      background-size: cover;
    }
  }
}
