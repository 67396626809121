.icon {
	background: url("svg/sprite.css.svg") no-repeat;
}

.icon-academia {
	background-position: 79.40074906367042% 78.77126654064273%;
	width: 13px;
	height: 13px;
}

.icon-arrow-left {
	background-position: 54.931335830212234% 75.27306967984934%;
	width: 13px;
	height: 11px;
}

.icon-arrow-left-white {
	background-position: 89.53922789539227% 64.60526315789474%;
	width: 11px;
	height: 10px;
}

.icon-arrow-right {
	background-position: 56.554307116104866% 75.27306967984934%;
	width: 13px;
	height: 11px;
}

.icon-bell {
	background-position: 99.87452948557089% 54.99047619047619%;
	width: 17px;
	height: 17px;
}

.icon-bell-active {
	background-position: 30.1129234629862% 92.32380952380953%;
	width: 17px;
	height: 17px;
}

.icon-calendar {
	background-position: 23.99497487437186% 84.92395437262357%;
	width: 18px;
	height: 16px;
}

.icon-candy-menu {
	background-position: 38.45177664974619% 80.75581395348837%;
	width: 26px;
	height: 26px;
}

.icon-caret-down {
	background-position: 91.81141439205955% 64.1231343283582%;
	width: 8px;
	height: 6px;
}

.icon-caret-up {
	background-position: 92.80397022332507% 64.1231343283582%;
	width: 8px;
	height: 6px;
}

.icon-change-password {
	background-position: 32.24592220828105% 92.32380952380953%;
	width: 17px;
	height: 17px;
}

.icon-change-password-active {
	background-position: 34.37892095357591% 92.32380952380953%;
	width: 17px;
	height: 17px;
}

.icon-chart-monitor {
	background-position: 98.59154929577464% 43.948919449901766%;
	width: 33px;
	height: 33px;
}

.icon-chat {
	background-position: 50.943396226415096% 88.66156787762907%;
	width: 19px;
	height: 19px;
}

.icon-chat-alt {
	background-position: 95.28061224489795% 60.87890625%;
	width: 30px;
	height: 30px;
}

.icon-chat-monitor {
	background-position: 65.13994910941476% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-chat-white {
	background-position: 25.63131313131313% 89.00191938579654%;
	width: 22px;
	height: 21px;
}

.icon-chat-writing {
	background-position: 28.373266078184113% 89.00191938579654%;
	width: 21px;
	height: 21px;
}

.icon-check {
	background-position: 99.87484355444306% 51.64150943396226%;
	width: 15px;
	height: 12px;
}

.icon-check-circle {
	background-position: 36.51191969887076% 92.14828897338403%;
	width: 17px;
	height: 16px;
}

.icon-check-cyan {
	background-position: 90.79601990049751% 64.48405253283302%;
	width: 10px;
	height: 9px;
}

.icon-check-green {
	background-position: 58.177278401997505% 75.27306967984934%;
	width: 13px;
	height: 11px;
}

.icon-chevron-down {
	background-position: 95.77114427860697% 57.03358208955224%;
	width: 10px;
	height: 6px;
}

.icon-chevron-right {
	background-position: 59.355638166047086% 75.27306967984934%;
	width: 7px;
	height: 11px;
}

.icon-chevron-up {
	background-position: 97.01492537313433% 57.03358208955224%;
	width: 10px;
	height: 6px;
}

.icon-circle-heart {
	background-position: 31.021437578814627% 89.00191938579654%;
	width: 21px;
	height: 21px;
}

.icon-clock {
	background-position: 81.02372034956305% 78.77126654064273%;
	width: 13px;
	height: 13px;
}

.icon-close {
	background-position: 73.34167709637046% 87.98861480075901%;
	width: 15px;
	height: 15px;
}

.icon-close-alt {
	background-position: 55.653266331658294% 88.49236641221374%;
	width: 18px;
	height: 18px;
}

.icon-close-alt-red {
	background-position: 57.914572864321606% 88.49236641221374%;
	width: 18px;
	height: 18px;
}

.icon-close-square {
	background-position: 75.21902377972465% 87.98861480075901%;
	width: 15px;
	height: 15px;
}

.icon-cog {
	background-position: 40.72681704260652% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-collaborate-sidebar {
	background-position: 99.10714285714286% 60.87890625%;
	width: 30px;
	height: 30px;
}

.icon-collaboration {
	background-position: 44.9936628643853% 80.59961315280464%;
	width: 25px;
	height: 25px;
}

.icon-collaboration-pop-up {
	background-position: 0 96.4033264033264%;
	width: 65px;
	height: 61px;
}

.icon-connect-sidebar {
	background-position: 68.70229007633588% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-documents {
	background-position: 89.96188055908513% 72.1747572815534%;
	width: 27px;
	height: 27px;
}

.icon-download {
	background-position: 60.175879396984925% 88.49236641221374%;
	width: 18px;
	height: 18px;
}

.icon-email {
	background-position: 72.264631043257% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-emoji {
	background-position: 0 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-envelope {
	background-position: 75.37688442211055% 78.77126654064273%;
	width: 18px;
	height: 13px;
}

.icon-exit {
	background-position: 2.132998745294856% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-facebook {
	background-position: 62.437185929648244% 88.49236641221374%;
	width: 18px;
	height: 18px;
}

.icon-file-person {
	background-position: 97.96437659033079% 50.333333333333336%;
	width: 28px;
	height: 32px;
}

.icon-folders {
	background-position: 48.162230671736374% 80.59961315280464%;
	width: 25px;
	height: 25px;
}

.icon-follow-sidebar {
	background-position: 75.82697201017811% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-globe-people {
	background-position: 93.39263024142312% 72.1747572815534%;
	width: 27px;
	height: 27px;
}

.icon-google {
	background-position: 64.69849246231156% 88.49236641221374%;
	width: 18px;
	height: 18px;
}

.icon-green-tick {
	background-position: 68.30188679245283% 79.52290076335878%;
	width: 19px;
	height: 18px;
}

.icon-grid {
	background-position: 42.73182957393484% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-heart {
	background-position: 99.74715549936789% 71.07074569789675%;
	width: 23px;
	height: 19px;
}

.icon-heart-active {
	background-position: 57.52212389380531% 79.82758620689656%;
	width: 23px;
	height: 20px;
}

.icon-ia-logo-candy {
	background-position: 56.98005698005698% 72.31517509727627%;
	width: 112px;
	height: 28px;
}

.icon-impact-sidebar {
	background-position: 91.47582697201018% 61.11764705882353%;
	width: 28px;
	height: 32px;
}

.icon-impact-type-1 {
	background-position: 65.8690176322418% 79.52290076335878%;
	width: 20px;
	height: 18px;
}

.icon-impact-type-2 {
	background-position: 63.25757575757576% 79.52290076335878%;
	width: 22px;
	height: 18px;
}

.icon-impact-type-3 {
	background-position: 33.54271356783919% 88.83141762452107%;
	width: 18px;
	height: 20px;
}

.icon-impact-type-4 {
	background-position: 66.95979899497488% 88.49236641221374%;
	width: 18px;
	height: 18px;
}

.icon-impact-type-5 {
	background-position: 35.89420654911839% 88.83141762452107%;
	width: 20px;
	height: 20px;
}

.icon-impact-type-6 {
	background-position: 38.41309823677582% 88.83141762452107%;
	width: 20px;
	height: 20px;
}

.icon-impact-type-7 {
	background-position: 40.931989924433246% 88.83141762452107%;
	width: 20px;
	height: 20px;
}

.icon-impact-type-8 {
	background-position: 43.45088161209068% 88.83141762452107%;
	width: 20px;
	height: 20px;
}

.icon-info {
	background-position: 99.87623762376238% 58.590225563909776%;
	width: 6px;
	height: 10px;
}

.icon-info-circle {
	background-position: 28.375% 84.60227272727273%;
	width: 14px;
	height: 14px;
}

.icon-insights {
	background-position: 4.265997490589712% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-insights-active {
	background-position: 6.398996235884567% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-invisible {
	background-position: 70.69182389937107% 79.07020872865274%;
	width: 19px;
	height: 15px;
}

.icon-it-logo-candy {
	background-position: 27.20797720797721% 81.38671875%;
	width: 112px;
	height: 30px;
}

.icon-lightning {
	background-position: 99.50433705080545% 45.60377358490566%;
	width: 7px;
	height: 12px;
}

.icon-link {
	background-position: 45.96977329974811% 88.83141762452107%;
	width: 20px;
	height: 20px;
}

.icon-link-alt {
	background-position: 99.87562189054727% 42.69083969465649%;
	width: 10px;
	height: 18px;
}

.icon-linkedin {
	background-position: 30.125% 84.60227272727273%;
	width: 14px;
	height: 14px;
}

.icon-linkedin-blue {
	background-position: 44.73684210526316% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-list {
	background-position: 73.08176100628931% 78.92045454545455%;
	width: 19px;
	height: 14px;
}

.icon-list-charcoal {
	background-position: 71.39272271016311% 87.98861480075901%;
	width: 17px;
	height: 15px;
}

.icon-lock {
	background-position: 99.62546816479401% 48.89523809523809%;
	width: 13px;
	height: 17px;
}

.icon-mendeley {
	background-position: 52.9559748427673% 75.41509433962264%;
	width: 19px;
	height: 12px;
}

.icon-minus {
	background-position: 98.2587064676617% 56.82156133828996%;
	width: 10px;
	height: 4px;
}

.icon-notebook-pen {
	background-position: 51.330798479087456% 80.59961315280464%;
	width: 25px;
	height: 25px;
}

.icon-notification {
	background-position: 25.534591194968552% 93.21153846153847%;
	width: 19px;
	height: 22px;
}

.icon-office-building {
	background-position: 41.75126903553299% 80.75581395348837%;
	width: 26px;
	height: 26px;
}

.icon-orcid {
	background-position: 26.256281407035175% 84.60227272727273%;
	width: 18px;
	height: 14px;
}

.icon-oval {
	background-position: 61.586121437422555% 66.11214953271028%;
	width: 7px;
	height: 7px;
}

.icon-paper-plane {
	background-position: 46.741854636591476% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-people {
	background-position: 69.22110552763819% 87.98861480075901%;
	width: 18px;
	height: 15px;
}

.icon-people-check {
	background-position: 79.38931297709924% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-people-collaborating {
	background-position: 96.82337992376112% 72.1747572815534%;
	width: 27px;
	height: 27px;
}

.icon-person {
	background-position: 48.7468671679198% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-person-add {
	background-position: 60.42983565107459% 79.82758620689656%;
	width: 23px;
	height: 20px;
}

.icon-phone {
	background-position: 82.95165394402035% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-pie-chart {
	background-position: 48.48866498740554% 88.83141762452107%;
	width: 20px;
	height: 20px;
}

.icon-plus {
	background-position: 60.44776119402985% 75.13157894736842%;
	width: 10px;
	height: 10px;
}

.icon-print {
	background-position: 8.531994981179423% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-privacy {
	background-position: 10.664993726474279% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-privacy-active {
	background-position: 12.797992471769135% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-projects {
	background-position: 14.93099121706399% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-projects-active {
	background-position: 17.063989962358846% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-public-profile {
	background-position: 19.196988707653702% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-public-profile-active {
	background-position: 21.329987452948558% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-researchgate {
	background-position: 82.44084682440847% 78.62264150943396%;
	width: 11px;
	height: 12px;
}

.icon-search {
	background-position: 31.875% 84.60227272727273%;
	width: 14px;
	height: 14px;
}

.icon-share {
	background-position: 50.75187969924812% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-share-white {
	background-position: 52.75689223057644% 92.14828897338403%;
	width: 16px;
	height: 16px;
}

.icon-social-networks {
	background-position: 23.462986198243414% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-social-networks-active {
	background-position: 25.59598494353827% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-target {
	background-position: 54.49936628643853% 80.59961315280464%;
	width: 25px;
	height: 25px;
}

.icon-toast-error {
	background-position: 8.463541666666666% 93.48790322580645%;
	width: 46px;
	height: 46px;
}

.icon-toast-success {
	background-position: 14.453125% 93.48790322580645%;
	width: 46px;
	height: 46px;
}

.icon-toast-warning {
	background-position: 20.442708333333332% 93.48790322580645%;
	width: 46px;
	height: 46px;
}

.icon-track-impact {
	background-position: 45.060658578856156% 63.4826883910387%;
	width: 237px;
	height: 51px;
}

.icon-track-impact-beta {
	background-position: 0 49.27312775330397%;
	width: 385px;
	height: 88px;
}

.icon-track-impact-home {
	background-position: 89.74358974358974% 49.27312775330397%;
	width: 385px;
	height: 88px;
}

.icon-track-impact-vv {
	background-position: 33.440514469453376% 74.78873239436619%;
	width: 192px;
	height: 45px;
}

.icon-track-impact-vv-beta {
	background-position: 83.95270270270271% 62.34%;
	width: 222px;
	height: 42px;
}

.icon-track-impact-vv-white {
	background-position: 0 84.18181818181819%;
	width: 191px;
	height: 47px;
}

.icon-track-impact-vv-white-beta {
	background-position: 0 64.66804979253112%;
	width: 260px;
	height: 60px;
}

.icon-track-impact-white {
	background-position: 0 74.78873239436619%;
	width: 208px;
	height: 45px;
}

.icon-trend {
	background-position: 77.63819095477388% 78.77126654064273%;
	width: 18px;
	height: 13px;
}

.icon-twitter {
	background-position: 27.728983688833125% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-university {
	background-position: 97.71573604060913% 54.99047619047619%;
	width: 26px;
	height: 17px;
}

.icon-university-building {
	background-position: 53.333333333333336% 88.66156787762907%;
	width: 19px;
	height: 19px;
}

.icon-user {
	background-position: 29.75% 99.94285714285715%;
	width: 14px;
	height: 17px;
}

.icon-visible {
	background-position: 50.441361916771754% 75.55765595463139%;
	width: 21px;
	height: 13px;
}

.icon-vv-logo-hivve {
	background-position: 0 0;
	width: 813.8px;
	height: 223.7px;
}

.icon-warning {
	background-position: 38.64491844416562% 92.14828897338403%;
	width: 17px;
	height: 16px;
}

.icon-whatsapp {
	background-position: 86.51399491094148% 72.31517509727627%;
	width: 28px;
	height: 28px;
}

.icon-whatsup {
	background-position: 77.09637046307886% 87.98861480075901%;
	width: 15px;
	height: 15px;
}

.icon-x-red {
	background-position: 61.691542288557216% 75.13157894736842%;
	width: 10px;
	height: 10px;
}

.icon-youtube {
	background-position: 27.889447236180903% 92.5%;
	width: 18px;
	height: 18px;
}

.icon-zoomin {
	background-position: 31.618569636135508% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

.icon-zoomout {
	background-position: 33.75156838143037% 99.94285714285715%;
	width: 17px;
	height: 17px;
}

