.maintenance-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  margin-bottom: $spacer * 2;

  &-content {
    height: 100vh;
    padding: $spacer * 10 $spacer * 7 $spacer * 8;

    .text {
      margin-left: $spacer * 7;
      width: 40%;

      .heading {
        @include font-type(type-36);
        color: $secondary;

        @media (max-width: 1199px) {
          @include font-type(type-38);
        }

        @media (max-width: 576px) {
          @include font-type(type-39);
          margin: 0 $spacer * 4;
        }
      }

      &-content {
        @include font-type(type-37);
        color: $charcoal;

        @media (max-width: 576px) {
          @include font-type(type-1);
        }
      }

      @media screen and (min-width: 1200px) and (max-width: 1300px) {
        width: 45%;
      }

      @media (max-width: 1199px) {
        text-align: center;
        margin: $spacer * 5 0 $spacer * 4;
        width: 100%;
      }

      @media (max-width: 576px) {
        margin: $spacer * 5 0 $spacer * 5;
      }
    }

    .image-wrapper {
      width: 60%;

      .maintenance-image {
        width: 100%;
        height: auto;
      }

      @media (max-width: 1199px) {
        text-align: center;
        padding-bottom: $spacer * 4;
        width: 100%;

        .maintenance-image {
          width: 70%;

          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }

      @media screen and (min-width: 1200px) and (max-width: 1300px) {
        width: 55%;
      }
    }

    @media (max-width: 1199px) {
      padding: $spacer * 4;
    }

    @media (max-width: 768px) {
      padding: $spacer * 3 $spacer * 0.5;
    }
  }

  .trackimpact-logo {
    background-image: url('/assets/icons/svg/track_impact_logo_hivve.svg');
    background-repeat: no-repeat;
    width: 330px;
    height: 73px;
    margin-top: $spacer;
  }
}
